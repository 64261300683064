import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import { selectIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.selectors'
import { setIsOpenLayovers } from '@redux/features/recommendedRoutes/recommendedRoutes.slice'
import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import FlagIcon from '@base/FlagIcon'
import { textLargeMediumStyles } from '@styles/typography/text'
import Title, { Title6 } from '@typography/Title'
import { TIMETABLE_FORM_HEIGHT } from '@widgets/checkout/timetable/components/SearchForm/constants/sizes'

type Props = {
  isMainPage: boolean
  layoverValue: SearchStationsOptionType
}

const StationLayover: FC<Props> = ({ isMainPage, layoverValue }) => {
  const { t } = useTranslation('Recommended routes')
  const dispatch = useAppDispatch()
  const isOpenPopup = useAppSelector(selectIsOpenLayovers)

  const onClick = () => {
    dispatch(setIsOpenLayovers(!isOpenPopup))
  }

  const content = (
    <>
      <Label>{t(isMainPage ? 'layoverIn' : 'layover')}</Label>
      <Title level={6}>{layoverValue.label}</Title>
    </>
  )

  return (
    <Container>
      <Wrapper $isMainPage={isMainPage} onClick={onClick}>
        {isMainPage ? content : <ContentWrapper>{content}</ContentWrapper>}
        {layoverValue.country_code && <FlagIcon code={layoverValue.country_code} width="24px" />}
      </Wrapper>
    </Container>
  )
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 209px;
  height: ${TIMETABLE_FORM_HEIGHT}px;
`

export const Wrapper = styled.div<{ $isMainPage: boolean }>`
  height: 52px;
  min-width: 169px;
  padding-inline: ${p => (p.$isMainPage ? 20 : 16)}px;
  display: flex;
  align-items: center;
  justify-content: ${p => (p.$isMainPage ? 'center' : 'space-between')};
  border-radius: ${p => p.theme.borderRadius.default};
  border: 1px dashed ${p => p.theme.colors.primary};
  cursor: pointer;

  ${Title6} {
    margin: 0 12px;
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    height: 48px;
  }
`

const Label = styled.span`
  ${textLargeMediumStyles};
  color: ${p => p.theme.colors.greyCarbon};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Title6} {
    margin: 0;
  }
`

export default memo(StationLayover)
