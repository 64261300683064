import { ConfigProvider, Form } from 'antd/es'
import { useTranslation } from 'next-i18next'
import type { FC, ReactNode } from 'react'
import { memo, useCallback, useMemo, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

import { FlagStyled } from '@base/FlagIcon'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import { searchFormSizes } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import MobileModalStations from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/MobileModalStations'
import StationLayover from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/stationLayover/StationLayover'
import StationSelectField from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/stationSelect/StationSelectField'
import StationOption from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/stationSelect/stationOption/StationOption'
import SwitchStations from '@components/mainPage/mainBlock/searchTrains/search/searchForm/stationsFields/switchStations/SwitchStations'
import { focusArrivalStation } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/elementsHandlers'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'

export const STATIONS_DROPDOWN_CLASSNAME = 'stations-dropdown'

export type StationFieldsProps = {
  arrivalStationValue?: SearchStationsOptionType
  departureStationValue?: SearchStationsOptionType
  disableClear?: boolean
  horizontal?: boolean
  isMainPage?: boolean
  isWidget?: boolean
  layoverValue?: SearchStationsOptionType
  setFieldValue: (name: string, value?: SearchStationsOptionType) => void
  switchIcon?: ReactNode
}

const StationsFields: FC<StationFieldsProps> = props => {
  const isMobile = useIsMobile()
  const { arrivalStationValue, departureStationValue, isMainPage, isWidget, layoverValue, setFieldValue } = props
  const { t } = useTranslation('Search form')
  const [focusedField, setFocusedField] = useState<SearchFormKeys.arrival | SearchFormKeys.departure | undefined>(
    undefined
  )

  const focusOnDeparture = useCallback(() => setFocusedField(SearchFormKeys.departure), [])
  const focusOnArrival = useCallback(() => setFocusedField(SearchFormKeys.arrival), [])
  const onBlur = useCallback(
    () =>
      setFocusedField(value => {
        if (value === SearchFormKeys.departure) {
          void Promise.resolve().then(() => {
            setTimeout(() => focusArrivalStation(), 100)
          })
          return SearchFormKeys.arrival
        }
      }),
    []
  )
  const onClose = useCallback(() => setFocusedField(undefined), [])

  const handlerSelectStation = useCallback(
    (field: SearchFormKeys) => {
      return (value?: SearchStationsOptionType) => {
        layoverValue && setFieldValue(SearchFormKeys.layover, undefined)
        setFieldValue(field, value)
      }
    },
    [layoverValue, setFieldValue]
  )

  const handlerSelectArrival = useMemo(() => handlerSelectStation(SearchFormKeys.arrival), [handlerSelectStation])
  const handlerSelectDeparture = useMemo(() => handlerSelectStation(SearchFormKeys.departure), [handlerSelectStation])

  const isFocusedArrival = focusedField === SearchFormKeys.arrival
  const isFocusedDeparture = focusedField === SearchFormKeys.departure
  const disableClear = isMobile && focusedField ? false : props.disableClear

  const arrivalStation = useMemo(
    () => (
      <StationsFormItem
        className="station"
        name={SearchFormKeys.arrival}
        rules={[{ required: true }]}
        validateStatus={undefined}
      >
        <StationSelectField
          directionLabel={t('to')}
          disableClear={disableClear}
          id={SearchFormKeys.arrival}
          isFocused={isFocusedArrival}
          onBlur={onBlur}
          onFocus={focusOnArrival}
          otherStationId={departureStationValue?.value}
          placeholder={t('enterArrivalCity')}
          setValue={handlerSelectArrival}
          value={arrivalStationValue}
        />
      </StationsFormItem>
    ),
    [
      arrivalStationValue,
      departureStationValue?.value,
      disableClear,
      focusOnArrival,
      handlerSelectArrival,
      isFocusedArrival,
      onBlur,
      t,
    ]
  )

  const departureStation = useMemo(
    () => (
      <StationsFormItem className="station" name={SearchFormKeys.departure} rules={[{ required: true }]}>
        <StationSelectField
          directionLabel={t('from')}
          disableClear={disableClear}
          id={SearchFormKeys.departure}
          isFocused={isFocusedDeparture}
          onBlur={onBlur}
          onFocus={focusOnDeparture}
          otherStationId={arrivalStationValue?.value}
          placeholder={t('enterDepartureCity')}
          setValue={handlerSelectDeparture}
          value={departureStationValue}
        />
      </StationsFormItem>
    ),
    [
      arrivalStationValue?.value,
      departureStationValue,
      disableClear,
      focusOnDeparture,
      handlerSelectDeparture,
      isFocusedDeparture,
      onBlur,
      t,
    ]
  )

  return (
    <ConfigProvider renderEmpty={() => <StationOption label={t('noSearchResults')} />}>
      {!focusedField || !isMobile ? departureStation : <MobileFieldStub />}
      {layoverValue ? (
        <StationLayover isMainPage={!!isMainPage} layoverValue={layoverValue} />
      ) : (
        <SwitchStations {...props} isWidget={isWidget} />
      )}
      {!focusedField || !isMobile ? arrivalStation : <MobileFieldStub />}

      {isMobile && (
        <MobileModalStations
          arrivalStation={arrivalStation}
          departureStation={departureStation}
          focusedField={focusedField}
          onClose={onClose}
        />
      )}
      {/*@ts-ignore*/}
      <GlobalStyles />
    </ConfigProvider>
  )
}

export const StationsFormItem = styled(Form.Item)`
  margin: 0;
  z-index: 1;
`

const MobileFieldStub = styled.div`
  height: ${searchFormSizes.fieldHeightMobile}px;
`

const GlobalStyles = createGlobalStyle`
  .${STATIONS_DROPDOWN_CLASSNAME} {
    background: ${p => p.theme.colors.backgroundContainer};

    .ant-select-item, .ant-select-item-empty {
      padding: 10px 30px 10px 24px;

      ${FlagStyled} {
        cursor: pointer;
      }
    }
  }
`

export default memo(StationsFields)
